import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Icon, Input, Select,  Loader, TextArea, Form, Modal } from 'semantic-ui-react';
import GConf from '../../AssetsM/generalConf';
import SKLT from '../../AssetsM/usedSlk';
import BackCard from '../Assets/backCard';
import InputLinks from '../Assets/linksData';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const AddCard = ({depData, setDepData,reglerBtnState, AddDepense, Genres, setModaT, setModaPS, loaderState}) =>{
    return(<>
        <div className={`card card-body shadow-sm mb-3 ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-4 text-white' : '' } `}>
            <h5 className='mb-1'>Ajouter Depenses :  <span onClick={() => setModaT(true)}>[NUM]</span> </h5>
            {/* <Select  className='w-100 shadow-sm rounded mb-3' placeholder='selectionner' value={depData.genre} fluid options={Genres} onChange={(e, data) => setDepData({...depData, genre: data.value })} /> */}
            <Input icon='pin' iconPosition='left' onClick={() => setModaT(true)} className='w-100 shadow-sm rounded mb-3' type='number' placeholder='Valeur' fluid  value={depData.valeur}  onChange={(e) => setDepData({...depData, valeur: e.target.value })}/>
            <h5 className='mt-1 mb-1'>Description :  <span onClick={() => setModaPS(true)}>[CLAVIER]</span> </h5>
            <Form>
                <TextArea placeholder='Ajouter Notes ici'  onClick={() => setModaPS(true)} value={depData.Description} className="mb-2 rounded" rows='2' onChange={(e) => setDepData({...depData, Description: e.target.value })}/>
            </Form>
            <Button disabled={reglerBtnState} className='rounded-pill bg-system-btn mb-3' fluid onClick={AddDepense}><Icon name='save' /> Ajouter <Loader inverted active={loaderState} inline size='tiny' className='ms-2'/></Button>
        </div>
    </>)
}

function DepenseRecette() {
    /*#########################[Const]##################################*/
    let caisseData = JSON.parse(localStorage.getItem(`Magazin_Caisse_LocalD`));
    const CaisseID = caisseData.C_ID;

    let [caisseLiveData, setCaisseLiveData] = useState({Caisse_Fond : 0})
   
    let [isChangingValue, setIsChangingValue] = useState()
    let [isChangingIndexValue, setIsChangingIndexValue] = useState()
    let [clavierValue, setClavierValue] = useState(0)
    let [reglerBtnState, setSaveBtnState] = useState(false)
    let [loaderState, setLS] = useState(false)

    let [loading , setLoading] = useState(false)
    let [modalT, setModaT] = useState(false)
    let [floatOpen, setFloatOpen] = useState(false)
    
    let [assetsListe, setAssetsListe] = useState([
        {id:1 , name : 'Sachet 100', numTot: 0, priceValue: 0.1},
        {id:2 , name : 'Sachet 200', numTot: 0, priceValue: 0.2},
        {id:3 , name : 'Sachet 300', numTot: 0, priceValue: 0.3},
        {id:4 , name : 'Sachet 500', numTot: 0, priceValue: 0.5},
        {id:5 , name : 'Ticket 1250', numTot: 0, priceValue: 1.250},
        {id:6 , name : 'Carte 5700', numTot: 0, priceValue: 5.7},
        {id:7 , name : 'Espece 1', numTot: 0, priceValue: 1},
        {id:8 , name : 'Espece 2', numTot: 0, priceValue: 1},
        {id:9 , name : 'Espece 3', numTot: 0, priceValue: 1},
    ])

    let sachetListe = [
        {id:1 , name : 'Sachet 100', numTot: 0, priceValue: 0.1},
        {id:2 , name : 'Sachet 200', numTot: 0, priceValue: 0.2},
        {id:3 , name : 'Sachet 300', numTot: 0, priceValue: 0.3},
        {id:4 , name : 'Sachet 500', numTot: 0, priceValue: 0.5},
    ]
    let carteListe =  [
        {id:1 , name : 'Ticket 1250', numTot: 0, priceValue: 1.250},
        {id:2 , name : 'Carte 5700', numTot: 0, priceValue: 5.7},
    ]

    let [totaleFond, setTotaleFond] = useState({sachet: sachetListe, cartes : carteListe, especeVal : []})

    /*#########################[UseEffect]##################################*/
    useEffect(() => {
        //check if stock if fixed 
        axios.post(`${GConf.ApiCaisseLink}/fond/select`, {
          forPID : caisseData.PID,
          caisseId : CaisseID,
        })
        .then(function (response) {
            setCaisseLiveData(response.data[0])
            setLoading(true)
        }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Impossible de charger les depenses</div></>, GConf.TostInternetGonf) 
              setLoading(true)
            }
        });
      }, [])

    /*#########################[Function]##################################*/
    const AddFonds = (addedValue) =>{
            // if (!depData.Description ) {toast.error("Ajouter Description !", GConf.TostErrorGonf)}
            // else if (!depData.Description ) {toast.error("Ajouter Description !", GConf.TostErrorGonf)}
            // else if (!depData.valeur ) {toast.error("Ajouter Valeur !", GConf.TostErrorGonf)}
            // else {
            //         setLS(true)
            //         setSaveBtnState(true)
            //         axios.post(`${GConf.ApiCaisseLink}/rt/depense/ajouter`, {
            //         forPID : caisseData.PID,
            //         camId : CaisseID,
            //         depenseD : depData

            //         })
            //         .then(function (response) {
            //             toast.success("Depense Ajouter !", GConf.TostSuucessGonf)
            //             setLS(false)
            //             setUPDL(!UpdateList)
            //             window.location.reload()
            //         }).catch((error) => {
            //             if(error.request) {
            //               toast.error(<><div><h5>Probleme de Connextion</h5> La depense à été enregistrer sur votre appareil</div></>, GConf.TostInternetGonf) 
            //               Offline.depensesToSave.push(depData)
            //               localStorage.setItem('Offline',  JSON.stringify(Offline));
            //               setLS(false)
            //             }
            //         });     
            //     } 
    }

    const UpdateValueOfFond = () =>{

        setAssetsListe(prevAssets =>
            prevAssets.map(asset =>
                asset.id === isChangingValue ? { ...asset, ...{ numTot: clavierValue } } : asset
            )
        );
        setModaT(false)
        setClavierValue(0) 
    }

    const CalculeSum = () =>{
        let sum = 0
        assetsListe.map((data) => {
            sum = sum + (data.numTot * data.priceValue)
        })
         return sum.toFixed(3)
    }

    const BtnClicked = (value) =>{
        if (value === 'C') { 
           // setDepData({...depData, valeur: 0 })
           setClavierValue(0)
        } 
        else if (value === '.' ) { 
            setFloatOpen(true)
        } 
        else {            
            if (floatOpen) {
                setClavierValue(parseFloat(JSON.stringify(clavierValue) + '.' + value))
               // setDepData({...depData, valeur: parseFloat(JSON.stringify(depData.valeur) + '.' + value) })
                setFloatOpen(false)
            } else {
                setClavierValue(parseFloat(JSON.stringify(clavierValue) + value))
                //setDepData({...depData, valeur: parseFloat(JSON.stringify(depData.valeur) + value)})
            }
        }
    }

    const OpenClavierModal = (targetToChange) => {
        setIsChangingValue(targetToChange)
        setModaT(true)
    }
    
    /*#########################[Card]##################################*/
    const ClavierCard = () =>{
        const BtnCard = (props) =>{
            return(<>
                <Button className={`shadow-sm ${props.bg == true ? 'bg-danger text-white ' : InputLinks.themeMode == 'dark' ? 'bg-dark-theme-3 text-white border-dark' : 'bg-white' } border mb-1 border-div `} style={{width:'100%', height:'60px', backgroundColor:'red'}} onClick={(e) => BtnClicked(props.value) } ><h2>{props.value}</h2></Button>
            </>)
        }
        return(<>
            <div className='row '>
                <div className='col-4 p-2'><BtnCard value={1} /></div>
                <div className='col-4 p-2'><BtnCard value={2} /></div>
                <div className='col-4 p-2'><BtnCard value={3} /></div>
                <div className='col-4 p-2'><BtnCard value={4} /></div>
                <div className='col-4 p-2'><BtnCard value={5} /></div>
                <div className='col-4 p-2'><BtnCard value={6} /></div>
                <div className='col-4 p-2'><BtnCard value={7} /></div>
                <div className='col-4 p-2'><BtnCard value={8} /></div>
                <div className='col-4 p-2'><BtnCard value={9} /></div>
                <div className='col-4 p-2'><BtnCard value={0} /></div>
                <div className='col-4 p-2'><BtnCard value='.' bg={floatOpen} /></div>
                <div className='col-4 p-2'><BtnCard value='C' /></div>
            </div>
        </>)
    }
 
    return ( <>
    <div className={`spesific-commp ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-2' : '' }`} style={{height: '100vh', overflow: 'scroll'}}>
            <BackCard data={InputLinks.backCard.rtDeps}/>
            <br />
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-lg-4'>
                        <div className={`card card-body shadow-sm mb-3 ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-4 text-white' : '' }`}>
                            <h1>Fond : {parseFloat(caisseLiveData.Caisse_Fond).toFixed(3)}</h1>
                        </div>
                        <div className={`card card-body shadow-sm mb-3 ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-4 text-white' : '' }`}>
                            Totale : <h1>{CalculeSum()}</h1> 
                            <Button disabled={reglerBtnState} className='rounded-pill bg-system-btn mb-3' fluid onClick={() => AddFonds()}><Icon name='save' /> Terminer  <Loader inverted active={loaderState} inline size='tiny' className='ms-2'/></Button>
                            <Button disabled={!reglerBtnState} className='rounded-pill bg-success text-white mb-3' fluid onClick={() => alert('Not Ready')}><Icon name='print' /> Imprimer  <Loader inverted active={loaderState} inline size='tiny' className='ms-2'/></Button>
                        </div>
                    </div>
                    <div className='col-12 col-lg-8'>
                        <div className={`card card-body shadow-sm mb-3 ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-4 text-white' : '' }`}>
                            <h5 className='mt-1'>SACHET : </h5>
                                <div onClick={() =>  OpenClavierModal(1)} className={`p-2 ps-4 mb-2 rounded-pill ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-2' : '' }`}  >
                                    <h6 className='mt-1 mb-1'>sachet 100 : {assetsListe[0].numTot} X {assetsListe[0].priceValue.toFixed(3)}  = {(assetsListe[0].numTot * assetsListe[0].priceValue).toFixed(3)}</h6>
                                </div>
                                <div onClick={() =>  OpenClavierModal(2)} className={`p-2 ps-4 mb-2 rounded-pill ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-2' : '' }`}  >
                                    <h6 className='mt-1 mb-1'>sachet 200 : {assetsListe[1].numTot} X {assetsListe[1].priceValue.toFixed(3)}  = {(assetsListe[1].numTot * assetsListe[1].priceValue).toFixed(3)}</h6>
                                </div>
                                <div onClick={() =>  OpenClavierModal(3)} className={`p-2 ps-4 mb-2 rounded-pill ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-2' : '' }`}  >
                                    <h6 className='mt-1 mb-1'>sachet 300 : {assetsListe[2].numTot} X {assetsListe[2].priceValue.toFixed(3)}  = {(assetsListe[2].numTot * assetsListe[2].priceValue).toFixed(3)}</h6>
                                </div>
                                <div onClick={() =>  OpenClavierModal(4)} className={`p-2 ps-4 mb-2 rounded-pill ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-2' : '' }`}  >
                                    <h6 className='mt-1 mb-1'>sachet 500 : {assetsListe[3].numTot} X {assetsListe[3].priceValue.toFixed(3)}  = {(assetsListe[3].numTot * assetsListe[3].priceValue).toFixed(3)}</h6>
                                </div>    
                                
                            <h5 className='mt-1'>CARTE ET TICKET : </h5>
                                <div onClick={() =>  OpenClavierModal(5)} className={`p-2 ps-4 mb-2 rounded-pill ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-2' : '' }`}  >
                                    <h6 className='mt-1 mb-1'>Ticket 1250 : {assetsListe[4].numTot} X {assetsListe[4].priceValue.toFixed(3)}  = {(assetsListe[4].numTot * assetsListe[4].priceValue).toFixed(3)}</h6>
                                </div>
                                <div onClick={() =>  OpenClavierModal(6)} className={`p-2 ps-4 mb-2 rounded-pill ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-2' : '' }`}  >
                                    <h6 className='mt-1 mb-1'>Carte 5700 : {assetsListe[5].numTot} X {assetsListe[5].priceValue.toFixed(3)}  = {(assetsListe[5].numTot * assetsListe[5].priceValue).toFixed(3)}</h6>
                                </div>
                            <h5 className='mt-1'>ESPECE : </h5>
                                <div onClick={() =>  OpenClavierModal(7)} className={`p-2 ps-4 mb-2 rounded-pill ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-2' : '' }`}  >
                                    <h6 className='mt-1 mb-1'>Espece 1 : {assetsListe[6].numTot} X {assetsListe[6].priceValue.toFixed(3)}  = {(assetsListe[6].numTot * assetsListe[6].priceValue).toFixed(3)}</h6>
                                </div>
                                <div onClick={() =>  OpenClavierModal(8)} className={`p-2 ps-4 mb-2 rounded-pill ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-2' : '' }`}  >
                                    <h6 className='mt-1 mb-1'>Espece 2 : {assetsListe[7].numTot} X {assetsListe[7].priceValue.toFixed(3)}  = {(assetsListe[7].numTot * assetsListe[7].priceValue).toFixed(3)}</h6>
                                </div>
                                <div onClick={() =>  OpenClavierModal(9)} className={`p-2 ps-4 mb-2 rounded-pill ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-2' : '' }`}  >
                                    <h6 className='mt-1 mb-1'>Espece 3 : {assetsListe[8].numTot} X {assetsListe[8].priceValue.toFixed(3)}  = {(assetsListe[8].numTot * assetsListe[8].priceValue).toFixed(3)}</h6>
                                </div>
                        </div>
                    </div>

                </div>
            </div>
            <br />
            <Modal
                    size='mini'
                    open={modalT}
                        
                    dimmer = 'blurring'
                    onClose={() => setModaT(false)}
                    onOpen={() => setModaT(true)}
                >
                    <Modal.Content  >
                        <h5>Valur : {clavierValue} </h5> 
                        <ClavierCard />  
                    </Modal.Content>
                    <Modal.Actions>
                                <Button className='rounded-pill float-start bg-success' negative onClick={ () => UpdateValueOfFond()}> <span className='bi bi-plus' ></span> Ajouter</Button>
                                <Button className='rounded-pill' negative onClick={ () => setModaT(false)}> <span className='bi bi-x' ></span> Fermer</Button>
                    </Modal.Actions>
            </Modal>
    </div>
            
    </> );
}

export default DepenseRecette;