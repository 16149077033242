import React, { useEffect, useState } from 'react';
import LinkCard from '../Assets/linksCard'
import InputLinks from '../Assets/linksData'
import BackCard from '../Assets/backCard'
import GConf from '../../AssetsM/generalConf';
import TableGrid from '../../Dashboard/Assets/tableGrid';
import { _ } from "gridjs-react";
import axios from 'axios';
import { Button,  Icon, Menu } from 'semantic-ui-react';
import TableImage from '../../Dashboard/Assets/tableImg';
import GoBtn from '../../Dashboard/Assets/goBtn';
import { toast } from 'react-toastify';
import { useNavigate} from 'react-router-dom';
import SKLT from '../../AssetsM/usedSlk';

function MesFactures() {
    /*#########################[Const]##################################*/
    let [factureList, setFactureList] = useState([SKLT.STableSlt]);
    let [reservationListTwo, setReservationListTwo] = useState([SKLT.TableSlt]);
    const [activeIndex, setActiveIndex] = useState(10)
    let caisseData = JSON.parse(localStorage.getItem(`Magazin_Caisse_LocalD`));
    const CaisseID = caisseData.C_ID; 
    let Offline = JSON.parse(localStorage.getItem(`Camion_Offline`));
    const navigate = useNavigate();

    /*#########################[UseEffect]##################################*/
    useEffect(() => {
        axios.post(`${GConf.ApiCaisseLink}/rt/factures`, {
          forPID : caisseData.PID,
          caisseId : CaisseID,
        })
        .then(function (response) {
            let testTable = []
            response.data.map( (getData) => testTable.push([       
            _(<TableImage image='facture.jpg' />),
            getData.T_ID,
            getData.CL_Name,
            new Date(getData.T_Date).toLocaleDateString('fr-FR').split( '/' ).reverse( ).join( '-' ),
            getData.T_Time.slice(0,5) ,
            (getData.Final_Value).toFixed(3),
            _(<StateCard status={getData.Pay_State} />),
            _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => NavigateFunction(`/C/L/ft/info/${getData.T_ID}`)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
            ],))
            setFactureList(testTable)
            setReservationListTwo(response.data)
        }).catch((error) => {
            if(error.request) {
                setFactureList([])
            }
        });
    }, [])

    /*#########################[Function]##################################*/
    const NavigateFunction = (link) => {  navigate(link) }
    const FetchByGenreReserv = (genre,tabIndex) =>{  
      console.log(reservationListTwo)
      let found = reservationListTwo.filter(element => element.Pay_State === genre)
      let commandeContainer = []
          found.map( (getData) => commandeContainer.push([          
            _(<TableImage image='facture.jpg' />),
            getData.T_ID,
            getData.CL_Name,
            new Date(getData.T_Date).toLocaleDateString('fr-FR').split( '/' ).reverse( ).join( '-' ),
            getData.T_Time.slice(0,5) ,
            (getData.Final_Value).toFixed(3),
            _(<StateCard status={getData.Pay_State} />),
            _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => NavigateFunction(`/C/L/ft/info/${getData.T_ID}`)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
            ],))
        setFactureList(commandeContainer)
        console.log(found)
        setActiveIndex(tabIndex)
    }

    const StateCard = ({ status }) => {
        const StateCard = (props) =>{ return <span className={`badge bg-${props.color}`}> {props.text} </span>}
        const statusCard = React.useCallback(() => {
          switch(status) {
            case 'Payee': return <StateCard color='success' text='Payeé' />;  
            case 'Credit': return <StateCard color='danger' text='Credit' /> ;
            case 'Waitting': return <StateCard color='warning' text='En Attend' /> ;
            default:  return <StateCard color='secondary' text='Indefinie' />;    
          }
        }, [status]);
      
        return (
          <div className="container">
            {statusCard()}
          </div>
        );
    };
    const CustomTabs = () => {
      return(<>
        <div className='row mb-3'>
          <div className='col-12 col-lg-10 align-self-center' >
            <div style={{width:'100%', overflowX: 'auto', overflowY : 'hidden',  whiteSpace:'nowrap'}}>
              <Menu secondary >
                  <Menu.Item active={activeIndex == 0} className='rounded-pill' onClick={ () => FetchByGenreReserv('Waitting',0)}>
                    <span className='text-warning'>
                      <b>
                        <span className='bi bi-hourglass-split'></span> En Attent
                      </b>
                    </span>
                  </Menu.Item>
                  <Menu.Item active={activeIndex == 1} className='rounded-pill' onClick={ () => FetchByGenreReserv('Credit',1)}>
                    <span className='text-danger'>
                      <b>
                        <span className='bi bi-credit-card'></span> Credit
                      </b>
                    </span>
                  </Menu.Item>
                  <Menu.Item active={activeIndex == 2} className='rounded-pill' onClick={ () => FetchByGenreReserv('Payee',2)}>
                    <span className='text-success'>
                      <b>
                        <span className='bi bi-cash-coin'></span> Payeé
                      </b>
                    </span>
                  </Menu.Item>
                  <Menu.Item active={activeIndex == 3} className='rounded-pill' onClick={ () => FetchByGenreReserv('Credit Payee',3)}>
                    <span className='text-info'>
                      <b>
                        <span className='bi bi-stripe'></span> Credit Payeé
                      </b>
                    </span>
                  </Menu.Item>
              </Menu>
            </div>
          </div>
           
        </div>
      </>)
    }

    return ( <>
            <div className={`spesific-commp ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-2' : '' }`} style={{height: '100vh', overflow: 'scroll'}}>
                <BackCard data={InputLinks.backCard.mf}/>
                <br />
                <div className='container'>
                    <CustomTabs  /> 
                    <TableGrid dark={true} tableData={factureList} columns={['*','ID','Client','Jour','Temps','Totale','Etat','Voir']} />
                </div>
            </div>
        </> );
}

export default MesFactures;