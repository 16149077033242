import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Icon, Input, Select,  Loader, TextArea, Form, Modal } from 'semantic-ui-react';
import GConf from '../../AssetsM/generalConf';
import SKLT from '../../AssetsM/usedSlk';
import BackCard from '../Assets/backCard';
import InputLinks from '../Assets/linksData';
import { useParams } from 'react-router-dom';

const AddCard = ({reglerBtnState, FindClientCredits, AddDepense, phoneNum, setPhoneNum,  setModaT,   loaderState}) =>{
    return(<>
        <div className={`card card-body shadow-sm mb-3 ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-4 text-white' : '' } `}>
            <h5 className='mb-1'>NUMERO TEL :  <span onClick={() => setModaT(true)}>[NUM]</span> </h5>
            <Input icon='pin' iconPosition='left' onClick={() => setModaT(true)} className='w-100 shadow-sm rounded mb-3' type='number' placeholder='Valeur' fluid  value={phoneNum}  onChange={(e) => setPhoneNum(e.target.value)}/>
            <Button disabled={reglerBtnState} className='rounded-pill bg-system-btn mb-3' fluid onClick={FindClientCredits}><Icon name='search' /> RECHERCHER <Loader inverted active={loaderState} inline size='tiny' className='ms-2'/></Button>
            
        </div>
    </>)
}

function DepenseRecette() {
    /*#########################[Const]##################################*/
    let {CID} = useParams()
    let caisseData = JSON.parse(localStorage.getItem(`Magazin_Caisse_LocalD`));
    const CaisseID = caisseData.C_ID; 
    let [phoneNum, setPhoneNum] = useState(0)
    let [trancheValue, setTrancheValue] = useState(0)
    let [trancheDate, setTrancheDate] = useState(new Date().toISOString().split('T')[0])
    let [loading , setLoading] = useState(true)
    let [selectedFacture, setSelectedFacture] = useState({Final_Value : 0 })
    let [tranchesListe, setTranchesListe] = useState([])
    let [totaleTranches, setTotaleTranches] = useState(0)

    let [creditList, setCreditList] = useState([])
    let [reglerBtnState, setSaveBtnState] = useState(false)
    let [loaderState, setLS] = useState(false)
    let [modalPS, setModaPS] = useState(false)
    let [modalT, setModaT] = useState(false)
    let [floatOpen, setFloatOpen] = useState(false)
    const [trancheToAdd, setTrancheToAdd] = useState({Tr_Date : new Date().toISOString().split('T')[0]})

    /*#########################[UseEffect]##################################*/
    useEffect(() => {
        axios.post(`${GConf.ApiCaisseLink}/electro/find-client`, {
            cliID : CID,
        })
        .then(function (response) {
            setLS(false)
            setCreditList(response.data)
            console.log(response.data)
        }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> La depense à été enregistrer sur votre appareil</div></>, GConf.TostInternetGonf) 
              setLS(false)
            }
        });   
    },[])

    /*#########################[Function]##################################*/
    
    const FindClientCredits = () =>{
        if (!phoneNum ) {toast.error("Ajouteé Numero du client  !", GConf.TostErrorGonf)}
        else {
                axios.post(`${GConf.ApiCaisseLink}/electro`, {
                    cliID : phoneNum,
                })
                .then(function (response) {
                    setLS(false)
                    setCreditList(response.data)
                    console.log(response.data)
                }).catch((error) => {
                    if(error.request) {
                      toast.error(<><div><h5>Probleme de Connextion</h5> La depense à été enregistrer sur votre appareil</div></>, GConf.TostInternetGonf) 
                      setLS(false)
                    }
                });     
            } 
    }
    const OpenPaymmentModal = (data) => {
        setSelectedFacture(data)
        //setModaPS(true)
        axios.post(`https://api.system.ansl.tn/API/Electro/facture/select-tranches`, {
            PID : GConf.PID,
            fid: data.T_ID
          })
          .then(function (response) {
            setTranchesListe(response.data) 
            let totTr = 0
            response.data.map((data) => {
                totTr = totTr + data.value
            })
            setTotaleTranches(totTr)
          }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Impossible de Charger la facture de son source   </div></>, GConf.TostInternetGonf)   
               
            }
          });
    }
    const AddDepense = () =>{
            if (!phoneNum ) {toast.error("Ajouter Valeur !", GConf.TostErrorGonf)}
            else if (!selectedFacture.CL_ID ) {toast.error("Ajouter Valeur !", GConf.TostErrorGonf)}
            else if (!trancheValue ) {toast.error("Ajouter Valeur !", GConf.TostErrorGonf)}
            else {
                    axios.post(`${GConf.ApiCaisseLink}/electro/add`, {
                    trancheData : {...selectedFacture, trancheValue: trancheValue, Caisse_ID : CaisseID }

                    })
                    .then(function (response) {
                        toast.success("Depense Ajouter !", GConf.TostSuucessGonf)
                        setLS(false)
                         
                    }).catch((error) => {
                        if(error.request) {
                          toast.error(<><div><h5>Probleme de Connextion</h5> La depense à été enregistrer sur votre appareil</div></>, GConf.TostInternetGonf) 
                          setLS(false)
                        }
                    });     
                } 
    }
    const BtnClicked = (value) =>{
        if (value === 'C') { 
            setPhoneNum(0)
        } 
        else if (value === '.' ) { 
            setFloatOpen(true)
        } 
        else {            
            if (floatOpen) {
                setPhoneNum(parseFloat(JSON.stringify(phoneNum) + '.' + value))
                setFloatOpen(false)
            } else {
                setPhoneNum(parseFloat(JSON.stringify(phoneNum) + value))
            }
        }
    }
    const BtnTrClicked = (value) =>{
        if (value === 'C') { 
            //setTrancheToAdd({...trancheToAdd, value : 0})
            setTrancheValue(0)
        } 
        else if (value === '.' ) { 
            setFloatOpen(true)
        } 
        else {            
            if (floatOpen) {
                //setTrancheToAdd({...trancheToAdd, value : parseFloat(JSON.stringify(trancheValue) + '.' + value)})
                setTrancheValue(parseFloat(JSON.stringify(trancheValue) + '.' + value))
                setFloatOpen(false)
            } else {
                //setTrancheToAdd({...trancheToAdd, value : parseFloat(JSON.stringify(trancheValue) + value)})
                setTrancheValue(parseFloat(JSON.stringify(trancheValue) + value))
            }
        }
    }
    const ImprimerTotale = () => {
        return(<>
            <Button disabled={reglerBtnState} className='rounded-pill bg-success mb-3 text-white' size='large' fluid onClick={() => alert('no')}><Icon name='print' /> Impimer Totale  <Loader inverted active={loaderState} inline size='tiny' className='ms-2'/></Button>
        </>)
    }

    const AddTrancheFuncion = () => {
        if (!trancheToAdd.Tr_Date) {toast.error("Ajoute Date !", GConf.TostErrorGonf) } 
        else if (!trancheValue) {toast.error("Ajoute Vzleur !", GConf.TostErrorGonf) } 
        else if (!selectedFacture.Client) {toast.error("Selectionner facture  !", GConf.TostErrorGonf) } 
        else {
             
            axios.post(`https://api.system.ansl.tn/API/Electro/facture/add-tranches-caisses`, {
                caisseId : CaisseID,
                trancheData: {...trancheToAdd, value : trancheValue, CL_ID: selectedFacture.Client, T_ID: selectedFacture.T_ID, OP_ID: selectedFacture.T_ID},
            })
            .then(function (response) {      
                toast.success("Tranche Ajouter !", GConf.TostSuucessGonf)
            }).catch((error) => {
                if(error.request) {
                toast.error(<><div><h5>Probleme de Connextion</h5> Operation Annuleé  </div></>, GConf.TostInternetGonf)   
                }
            });
        }
    }

    /*#########################[Card]##################################*/
    const DepensesListCard = (props) =>{
        return(<>
                <div onClick={() => OpenPaymmentModal(props.data)} className={`card p-2 shadow-sm mb-3 border-div ${props.data.T_ID == selectedFacture.T_ID ? 'border border-danger' : ''}  ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-1 text-white' : '' }`}>
                    <div className='row'>
                        
                        <div className='col-4 align-self-center '>
                            <b>{new Date(props.data.T_Date).toLocaleDateString()}</b>
                        </div>
                        <div className='col-4 align-self-center '>
                            <span className='ms-3'>{parseFloat(props.data.Final_Value).toFixed(3)}</span>
                        </div>
                        <div className='col-4 align-self-center '>
                        <   span className='ms-3'>{parseFloat(props.data.Reste).toFixed(3)}</span>
                        </div>
                        <div className='col-12 align-self-center text-start'>
                            
                            <ul>
                                {JSON.parse(props.data.Articles).map( (artData, index) => 
                                    <li key={index +1 }>
                                         {artData.Name}  :  {artData.Qte} X  {artData.Prix.toFixed(3)} = {artData.PU}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </>)
    }
    const TotalCard = () =>{
        return(<>
            <div className={`card card-body shadow-sm mb-3 ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-4 text-white' : '' }`}>
                Liste des Credit  : 
                {loading ?  
                                <>
                                    {creditList.map((data, index) => <DepensesListCard key={index} data={data} />)}
                                </>
                        : SKLT.CardList }

                        <br />
            </div>
        </>)
    }
    const ClavierCard = (props) =>{
        const BtnCard = (props) =>{
            return(<>
                <Button className={`shadow-sm ${props.bg == true ? 'bg-danger text-white ' : InputLinks.themeMode == 'dark' ? 'bg-dark-theme-3 text-white border-dark' : 'bg-white' } border mb-1 border-div `} style={{width:'100%', height:'60px', backgroundColor:'red'}} onClick={(e) => { if(props.tranche) { BtnTrClicked(props.value) } else { BtnClicked(props.value)} }  } ><h2>{props.value}</h2></Button>
            </>)
        }
        return(<>
            <div className='row '>
                <div className='col-4 p-2'><BtnCard tranche={props.tranche} value={1} /></div>
                <div className='col-4 p-2'><BtnCard tranche={props.tranche} value={2} /></div>
                <div className='col-4 p-2'><BtnCard tranche={props.tranche} value={3} /></div>
                <div className='col-4 p-2'><BtnCard tranche={props.tranche} value={4} /></div>
                <div className='col-4 p-2'><BtnCard tranche={props.tranche} value={5} /></div>
                <div className='col-4 p-2'><BtnCard tranche={props.tranche} value={6} /></div>
                <div className='col-4 p-2'><BtnCard tranche={props.tranche} value={7} /></div>
                <div className='col-4 p-2'><BtnCard tranche={props.tranche} value={8} /></div>
                <div className='col-4 p-2'><BtnCard tranche={props.tranche} value={9} /></div>
                <div className='col-4 p-2'><BtnCard tranche={props.tranche} value={0} /></div>
                <div className='col-4 p-2'><BtnCard tranche={props.tranche} value='.' bg={floatOpen} /></div>
                <div className='col-4 p-2'><BtnCard tranche={props.tranche} value='C' /></div>
            </div>
        </>)
    }
    return ( <>
        <div className={`spesific-commp ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-2' : '' }`} style={{height: '100vh', overflow: 'scroll'}}>
                <BackCard data={InputLinks.backCard.electro}/>
                <br />
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-lg-4'>
                            <TotalCard />
                            <div className={`card card-body ${InputLinks.themeMode == 'dark' ? 'bg-dark-theme-1 text-white' : '' }`}>
                            <h5>Liste des Tranches </h5> 
                                <ul>
                                    {tranchesListe.map((data,index) => <li key={index}>{new Date(data.Tr_Date).toLocaleDateString('fr-FR')} : {data.value.toFixed(3)} </li>)}
                                </ul>
                                = {totaleTranches.toFixed(3)}
                                <h2 className='mt-1'>Reste : {(selectedFacture.Final_Value - totaleTranches).toFixed(3)}</h2>
                                 {/*<h4>Reste Enregistrer: {parseFloat(factureData.Reste).toFixed(3)}</h4>
                                <br />
                                <br />
                                <br /> */}
                            </div>
                        </div>
                        <div className='col-12 col-lg-8'>
                                <div className='row'>
                                        <div className='col-8'>
                                            <Input icon='calendar alternate' type='date' size="large" iconPosition='left' value={trancheToAdd.Tr_Date}   fluid className='mb-1'   onChange={(e) => setTrancheToAdd({...trancheToAdd, Tr_Date: e.target.value })}/>
                                            <ClavierCard tranche />  
                                            <Button className='btn-succes mb-4' size="large" fluid onClick={() => AddTrancheFuncion()} >Enregistrer</Button>
                                            <Button disabled={reglerBtnState} className='rounded-pill bg-success mb-3 text-white' fluid onClick={() => alert('done')}><Icon name='print' /> Impimer Recue  <Loader inverted active={loaderState} inline size='tiny' className='ms-2'/></Button>
                                        </div>
                                        <div className='col-4'>
                                            <ImprimerTotale />
                                            <h5 className={`mb-1  ${InputLinks.themeMode == 'dark' ? 'text-white' : '' }`}> <span className='bi bi-person-fill'></span> :  { selectedFacture.CL_Name}</h5>
                                            <h5 className={`mb-1 mt-1  ${InputLinks.themeMode == 'dark' ? 'text-white' : '' }`}> <span className='bi bi-person-fill'></span> :  { selectedFacture.Ar_Name}</h5>
                                            <h1 className={` ${InputLinks.themeMode == 'dark' ? 'text-white' : '' }`}>Tranche : {trancheValue.toFixed(3)}</h1>
                                            
                                        </div>
                                </div>
                            
                        </div>
                        <Modal
                                size='small'
                                open={modalPS}
                            
                                dimmer = 'blurring'
                                onClose={() => setModaPS(false)}
                                onOpen={() => setModaPS(true)}
                            >
                                <Modal.Content  >
                                    <div className='row'>
                                        <div className='col-6'>
                                            <ClavierCard tranche />  
                                        </div>
                                        <div className='col-6'>
                                            <h1>{trancheValue}</h1>
                                            <br />
                                            <br />
                                            <Button className='btn-succes mb-4' fluid onClick={() => AddDepense()} >Enregistrer</Button>
                                        </div>
                                    </div>
                                </Modal.Content>
                                <Modal.Actions>
                                            <Button className='rounded-pill' negative onClick={ () => setModaPS(false)}> <span className='bi bi-x' ></span> Fermer</Button>
                                </Modal.Actions>
                        </Modal>
                        <Modal
                                size='mini'
                                open={modalT}
                                
                                dimmer = 'blurring'
                                onClose={() => setModaT(false)}
                                onOpen={() => setModaT(true)}
                            >
                                <Modal.Content  >
                                    <h5>Valur : {phoneNum} </h5> 
                                    <ClavierCard />  
                                </Modal.Content>
                                <Modal.Actions>
                                            <Button className='rounded-pill' negative onClick={ () => setModaT(false)}> <span className='bi bi-x' ></span> Fermer</Button>
                                </Modal.Actions>
                        </Modal>
                    </div>
                </div>
                <br />
        </div>
            
    </> );
}

export default DepenseRecette;